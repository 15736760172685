import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import { ContactForm, HeroImage, ContactData } from "@components";

const ContactUs = () => {
    const { placeholder } = useStaticQuery(
        graphql`
            query {
                placeholder: file(relativePath: { eq: "contact-us.webp" }) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    return (
        <>
            <HeroImage
                title={"Contact Us"}
                image={placeholder.childImageSharp.fluid}
            />
            <div className="text-gray-900 border-b border-primary">
                <div className="max-w-screen-xl px-8 flex flex-col md:flex-row justify-between gap-8 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto">
                    <div className="flex flex-col justify-between w-full">
                        <div>
                            <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
                                Lets talk about everything!
                            </h2>
                            {/* <div className="text-gray-700 mt-8">
                                Hate forms? Send us an{" "}
                                <span className="underline">email</span>{" "}
                                instead.
                            </div> */}

                            <div className="mt-16">
                                <ContactData />
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
